import React from "react";

export const IconButton = (props) => {
    const onClickEvent = () => {
        props.onClick();
    }
    return <button className="icon-button" href="#" onClick={onClickEvent}>
        <img src={props.image} alt={props.tooltip} />
    </button>
}
