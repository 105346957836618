import React from "react";
import IconRotatingActive from "./../assets/icon_rotation_start.svg";
import IconRotatingInactive from "./../assets/icon_rotation_stop.svg";
import IconHelp from "./../assets/icon_help.svg";
import IconMesh from "./../assets/icon_mesh.svg";
import IconClose from "./../assets/icon_close.svg";
import IconMeshOn from "./../assets/icon_mesh_on.svg";
import IconAR from "./../assets/icon_ar.svg";
import IconARKit from "./../assets/icon_arkit.svg";
import {IconButton} from "./IconButton";
import MediaQuery from "react-responsive";
import {IconLink} from "./IconLink";

export const Footer = (props) => {
    const onChangeRotation = () => {
        props.onChangeSettings({...props.settings, rotating: !props.settings.rotating});
    };
    const onChangeMesh = () => {
        props.onChangeSettings({...props.mesh, mesh: !props.settings.mesh});
    };
    const onShowHelp = () => {
        props.onChangeSettings({...props.settings, showHelp: true});
    };
    const onChangeAR = () => {
        if(props.settings.ar){
            window.location.reload();
        } else {
            props.onChangeSettings({...props.settings, ar: true});
        }
    };
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    return <div className="ui">
        <div className="buttons">
            {props.settings.ar ? <IconButton image={IconClose} tooltip="AR: aus" onClick={onChangeAR}/> : <>
                <IconButton image={IconHelp} tooltip="Hilfe" onClick={onShowHelp}/>
                <IconButton image={props.settings.rotating ? IconRotatingInactive : IconRotatingActive}
                            tooltip="Rotieren: an" onClick={onChangeRotation}/>
                <IconButton image={props.settings.mesh ? IconMeshOn : IconMesh} tooltip="Mesh: an"
                            onClick={onChangeMesh}/>
                <MediaQuery maxDeviceWidth={1224}>
                    {iOSSafari && props.settings.filename ? <IconLink image={IconARKit} href={`/models/${props.settings.filename}.usdz`}/> :
                        <IconButton image={IconAR} tooltip="AR: an" onClick={onChangeAR}/>}
                </MediaQuery></>}

        </div>
        <a href={props.settings.styles.link} target="_blank" rel="noreferrer">
            <img src="/logo192.png" alt="logo"/>
        </a>
    </div>
}
