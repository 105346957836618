import {useRef} from "react";
import {useHitTest, Interactive} from "@react-three/xr";
import {useLoader} from "@react-three/fiber";
import {TextureLoader} from "three";

export const Target = (props) => {
    const ref = useRef();
    const colorMap = useLoader(TextureLoader, '/target.png');
    useHitTest((hit) => {
        hit.decompose(ref.current.position, ref.current.rotation, ref.current.scale)
    });
    const onClickCube = () => {
        props.newPosition(ref.current.position);
    };
    return (
        <Interactive onSelect={onClickCube}>
            <mesh
                {...props}
                ref={ref}
                rotation={[0,0,0]}
            >
                <boxBufferGeometry args={[0.1, 0, 0.1]}/>
                <meshStandardMaterial map={colorMap}/>
            </mesh>
        </Interactive>
    );
}
