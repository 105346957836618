import React from "react";
import "./Modal.css";
import IconClose from "./../../assets/icon_close.svg";
import {IconButton} from "./../IconButton";

export const Modal = (props) => {
    const onCloseModal = () => {
        props.onChangeSettings({...props.settings, showHelp: false});
    };
    const onClickOverlay = (event) => {
        event.preventDefault();
        if(event.target.getAttribute('name') === "overlay") {
            onCloseModal();
        }
    };
    return <div className="modal" name="overlay" onClick={onClickOverlay}>
        <div className="card">
            <IconButton image={IconClose} tooltip="Schliessen" onClick={onCloseModal}/>
            {props.children}
        </div>
    </div>
}
