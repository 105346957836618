import React, {Suspense, useState} from "react";
import {Canvas} from "react-three-fiber";
import {Scene} from "./objects/Scene";
import {
    useParams, useLocation
} from "react-router-dom";
import {ARCanvas, DefaultXRControllers} from "@react-three/xr";
import {Target} from "./objects/Target";

export const ThreeCanvas = (props) => {
    const [currentPosition, setCurrentPosition] = useState([0, 0, 0]);
    const [gltf, setGltf] = useState();
    let params = useParams();
    let location = useLocation();
    let scale = new URLSearchParams(location.search).get("scale");
    let camera = props.settings.camera;

    const setNewPosition = (pos) => {
        setCurrentPosition([pos.x, pos.y, pos.z]);
    }
    const onFinishedLoading = (status, message, model) => {
        setGltf(model);
        props.onFinishedLoading(params.filename, status, message);
    }

    return <div className="canvas">
        {props.settings.ar && gltf ? <ARCanvas sessionInit={{requiredFeatures: ['hit-test']}}>
            <Suspense fallback={null}>
                <ambientLight/>
                <Target newPosition={setNewPosition}/>
                <primitive position={currentPosition} object={gltf.scene} dispose={null} scale={[0.5, 0.5, 0.5]}/>

                <DefaultXRControllers/></Suspense>
        </ARCanvas> : <Canvas camera={{position: [camera.x, camera.y, camera.z], fov: camera.fov}}>
            <Scene filename={params.filename}
                   sky={props.settings.styles.sky}
                   rotating={props.settings.rotating}
                   scale={parseFloat(scale)}
                   lights={props.settings.lights}
                   offset={props.settings.offset}
                   mesh={props.settings.mesh}
                   ar={props.settings.ar}
                   onFinishedLoading={onFinishedLoading}/>
        </Canvas>}
    </div>
}
