import React from "react"
import ImageMouse1 from "./../../assets/controls_mouse_1.svg";
import ImageMouse2 from "./../../assets/controls_mouse_2.svg";
import ImageMouse3 from "./../../assets/controls_mouse_3.svg";
import ImageTouch1 from "./../../assets/controls_touch_1.svg";
import ImageTouch2 from "./../../assets/controls_touch_2.svg";
import ImageTouch3 from "./../../assets/controls_touch_3.svg";
import {isMobile} from "react-device-detect";

export const Controls = () => {

    const item = (image, text) => {
        return <div className="controls-item" key={image.alt}>
            <img src={image.src} alt={image.alt}/>
            <span>{text}</span>
        </div>
    };
    const items = isMobile ? [
        item({src: ImageTouch1, alt: "Swipe"}, "Drehen"),
        item({src: ImageTouch2, alt: "Pinch"}, "Zoomen"),
        item({src: ImageTouch3, alt: "Doublefinger Swipe"}, "Bewegen"),
    ] : [
        item({src: ImageMouse1, alt: "Maus 1"}, "Drehen"),
        item({src: ImageMouse2, alt: "Maus 2"}, "Zoomen"),
        item({src: ImageMouse3, alt: "Maus 3"}, "Bewegen"),
    ];

    return <>
        <h3>Steuerung</h3>
        {
            items
        }
    </>
}
