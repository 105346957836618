import React from "react";
import {useLoader} from "@react-three/fiber";
import {TextureLoader, BackSide} from "three";

export const Dome = (props) => {
    const domeImage = useLoader(TextureLoader, `/${props.image}`);
    return (
        <mesh>
            <sphereBufferGeometry attach="geometry" args={[500, 60, 40]}/>
            <meshBasicMaterial attach="material" map={domeImage} side={BackSide}/>
        </mesh>
    );
}
