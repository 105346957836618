import React, {useEffect, useMemo, useRef, useState} from "react";
import {useFrame} from "react-three-fiber";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

export const Asset = (props) => {
    const meshRef = useRef();
    const {mesh, onFinishedLoading} = props;
    const url = decodeURIComponent(props.url);
    const [gltf, setGltf] = useState();
    const scale = props.scale ? props.scale : 1;
    const scaleMultiplier = 50;

    useMemo(() => new GLTFLoader().load(url, (model) => {
        console.log("Model loaded");
        setGltf(model);
        onFinishedLoading(true, "success", model);
    }, null, (error) => {
        onFinishedLoading(false, `Fehler beim laden (${url})`);
    }), [url]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(()=>{
        if(gltf){
            var object = gltf.scene;
            object.traverse((node) => {
                if (!node.isMesh) return;
                node.material.wireframe = mesh;
            });
        }

    },[mesh, gltf]);

    useFrame(() => {
        gltf && meshRef.current &&  (meshRef.current.rotation.y += props.rotating ? 0.03 : 0)
    });

    if(gltf) {
        return <primitive ref={meshRef} object={gltf.scene} dispose={null} scale={[scale*scaleMultiplier,scale*scaleMultiplier,scale*scaleMultiplier]} position={props.position} />
    } else {
        return <></>
    }
}
