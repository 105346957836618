import React, {useState, useEffect} from 'react';
import './App.css';
import {Footer} from "./components/Footer";
import {ThreeCanvas} from "./components/ThreeCanvas";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {Message} from "./components/Message";
import {Loading} from "./components/Loading";
import {Modal} from "./components/modals/Modal";
import {Controls} from "./components/modals/Controls";

function App() {
    const [settings, setSettings] = useState({
        filename: undefined,
        rotating: false,
        showHelp: false,
        configLoaded: false,
        lights: [],
        camera: {},
        offset: {x: 0, y: 0, z: 0},
        mesh: false,
        ar: false,
        styles: {link: "", background: "white", sky: ""}
    });
    const [loading, setLoading] = useState({isLoading: true, success: false, message: ""});

    useEffect(() => {
        async function fetchUrl() {
            const response = await fetch("/config.json");
            const json = await response.json();
            setSettings({
                ...settings,
                configLoaded: true,
                lights: json.lights,
                camera: json.camera,
                offset: json.offset,
                styles: json.styles
            });
        }
        fetchUrl();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const onFinishedLoading = (filename, success, message) => {
        setLoading({...loading, isLoading: false, success, message: message ? message : ""});
        setSettings({...settings, filename});
    };
    const onChangeSettings = (newSettings) => {
        setSettings({...settings, ...newSettings});
    };
    return (<Router>
            <div className="app" style={{background: settings.styles?.background || "white"}}>
                <Switch>
                    <Route path="/" exact>
                        <Message text="Kein Model"/>
                    </Route>
                    <Route path="/m/:filename">
                        {settings.configLoaded &&
                        <ThreeCanvas settings={settings} onFinishedLoading={onFinishedLoading}/>}
                        <Loading fadeOut={!loading.isLoading && settings.configLoaded}/>
                        {!loading.isLoading && !loading.success && <Message text={loading.message}/>}
                    </Route>
                </Switch>
                <Footer settings={settings} onChangeSettings={onChangeSettings}/>
                <div className="vignette"/>
                {settings.showHelp && <Modal settings={settings} onChangeSettings={onChangeSettings}>
                    <Controls/>
                </Modal>
                }
            </div>
        </Router>
    );
}

export default App;
