import React, {Suspense} from "react";
import {CameraController} from "./CameraController";
import {Asset} from "./Asset";
import {Dome} from "./Dome";

export const Scene = (props) => {
    let offset = [props.offset.x, props.offset.y, props.offset.z] || [0, 0, 0];
    console.log(props.sky);
    return (
        <>
            <CameraController/>
            <ambientLight/>
            {props.lights.map((light, index) => <pointLight key={`light${index}`} position={[light.x, light.y, light.z]}
                                                            intensity={light.intensity || 1}/>)}
            {props.sky && <Suspense fallback={null}>
                <Dome image={props.sky}/>
            </Suspense>}
            <Suspense fallback={null}>
                <Asset url={`/models/${props.filename}.glb`} rotating={props.rotating} scale={props.scale}
                       position={offset} mesh={props.mesh} onFinishedLoading={props.onFinishedLoading}
                       ar={props.ar}/>
            </Suspense>
        </>
    )
}
/*
{props.sky && <Dome image={props.sky}/>}
 <Asset url={`/models/${props.filename}.glb`} rotating={props.rotating} scale={props.scale}
                       position={offset} mesh={props.mesh} onFinishedLoading={props.onFinishedLoading}
                       ar={props.ar}/>
 */
